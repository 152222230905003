<template>
  <div class="user_speaker">
    <el-card>
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.keyword"
            size="medium"
            placeholder="输入姓名，手机，邮箱，公司名称"
          />
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.waId" placeholder="小助手" size="medium">
            <el-option label="全部" :value="-1" />

            <el-option
              v-for="(item) in $store.state.NormalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select v-model="form_2.status" placeholder="状态" size="medium">
            <el-option label="全部" :value="-1" />
            <el-option label="下架" :value="0"></el-option>
            <el-option label="上架" :value="1"></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: auto">
          <fuzzy-selection
            tipName="活动名称"
            size="medium"
            width="100%"
            :value.sync="form_2.activityId"
            :type="4"
          ></fuzzy-selection>
        </nav>
        <nav class="input" style="width: auto">
          <region :val1.sync="form_2.province" :val2.sync="form_2.city"></region>
        </nav>
        <nav class="input">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="subimt">{{
            '搜索'
          }}</el-button>

          <el-button
            v-show="$store.state.btn_if.indexOf('user_speaker_excel') != -1"
            type="primary"
            size="small"
            :loading="!btn"
            @click="beforedown"
            >{{ '导出Excel' }}</el-button
          >
        </nav>
        <nav class="input" style="width: auto;">
           <router-link to="/guest_edit">
            <el-button
              v-show="$store.state.btn_if.indexOf('user_speaker_add') != -1"
              type="primary"
              size="small"
              >添加嘉宾</el-button
            ></router-link
          >
        </nav>
        <!-- <nav class="right">
         
        </nav> -->
      </nav>
    </el-card>
    <el-dialog title="" :visible.sync="dioA" width="30%" >
      <Show @form="checkf" />
      <span slot="footer">
        <el-button @click="dioA = false">取消</el-button>
        <el-button type="primary" @click="download">{{
          btn ? '导出' : '导出中..'
        }}</el-button>
      </span>
    </el-dialog>

    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" width="100" label="编号" />
        <el-table-column prop="realName" label="姓名">
          <template #default="{row}">
            <p>{{ row.realName }}</p>
            <el-tag v-if="fUser(row)" size="small" :type="fUserColor(row)">
              {{ fUser(row) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="desc"
          label="简介"
          width="120"
          :show-overflow-tooltip="true"
        />
        <el-table-column prop="province" label="所在城市">
          <template slot-scope="{ row }">
            {{ row.province }}<span v-show="row.province && row.city">·</span
            >{{ row.city }}
          </template>
        </el-table-column>
        <el-table-column prop="company" label="公司对外名称" />
        <el-table-column prop="job" label="职务对外名称" />
        <el-table-column prop="phone" label="手机号" width="120" />
        <el-table-column prop="email" label="邮箱" />
        <el-table-column prop="avatar" label="嘉宾宣传照" width="180">
          <template slot-scope="ew">
            <img
              :src="baseurl + yu + ew.row.avatar"
              alt=""
              style="width: 150px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="activityName" label="活动" width="300">
          <template slot-scope="{ row }">
            <el-tag
              v-for="item in !!row.activityName && row.activityName.split(',')"
              v-show="row.activityName"
              :key="item"
              type="info"
              size="mini"
              effect="dark"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="" label="状态">
          <template #default="{row}">
            <el-tag size="small" :type="row.status == 1 ? '' : 'warning'">
              {{ row.status == 1 ? '上架' : '下架' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="" label="排序">
          <template #default="{row}">
            <Remark
              @getlist="getlist"
              :row="row"
              url="/admin/UserSpeaker/editOrder"
              rowKey="orderNum"
            ></Remark>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <template slot-scope="{ row }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                v-model="row.remark"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row)"
              />
            </div>
            <span
              v-show="!row.pai"
              @click="
                $store.state.btn_if.indexOf('user_speaker_remark') != -1 &&
                  showinput(row)
              "
            >
              {{ row.remark }}</span
            >
            <i
              v-show="!row.pai"
              :class="
                $store.state.btn_if.indexOf('user_speaker_remark') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('user_speaker_remark') != -1 &&
                  showinput(row)
              "
            />
          </template>
        </el-table-column>
        <el-table-column prop="waId" label="小助手" width="120">
          <template slot-scope="{ row }">
            <el-select
              v-model="row.waId"
              placeholder="小助手"
              multiple
              :multiple-limit="3"
              size="medium"
              :disabled="
                !($store.state.btn_if.indexOf('user_speaker_assistant') != -1)
              "
              @change="chagne(row)"
            >
              <el-option
                v-for="(item, index) in $store.state.NormalList"
                :key="item.id"
                :label="item.name"
                :value="item.id + ''"
              />
            </el-select>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="时间" />

        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="ew">
            <router-link :to="'/guest_edit?id=' + ew.row.id">
              <el-button
                v-show="$store.state.btn_if.indexOf('user_speaker_edit') != -1"
                type="text"
                icon="el-icon-edit"
                size="mini"
                class="skyblue"
                @click="edit(ew.row.id)"
                >编辑</el-button
              >
            </router-link>

            <el-button
              v-show="$store.state.btn_if.indexOf('user_speaker_delete') != -1"
              type="text"
              icon="el-icon-delete"
              size="mini"
              class="red"
              @click="open(ew.row.id)"
              >删除</el-button
            >
            <el-button
              v-show="$store.state.btn_if.indexOf('user_speaker_show') != -1"
              type="text"
              size="mini"
              icon="el-icon-camera"
              class="green"
              @click="information(ew.row)"
              >查看信息</el-button
            >
            <el-button
              type="text"
              size="mini"
              icon="el-icon-s-promotion"
              @click="shelves(ew.row)"
            >
              {{ +ew.row.status ? '下架' : '上架' }}
            </el-button>
            <el-button
              v-show="$store.state.btn_if.indexOf('user_speaker_down') != -1"
              type="text"
              size="mini"
              icon="el-icon-download"
              class="yellow"
              @click="datum(ew.row)"
              >资料下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <el-dialog title="新增报告" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="标题">
          <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="form.desc" />
        </el-form-item>
        <el-form-item label="来源">
          <el-input v-model="form.source" />
        </el-form-item>
        <el-form-item label="地区">
          <el-select v-model="form.areaCode" placeholder="请选择">
            <el-option
              v-for="item in area.reportArea"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              :data="action"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="查看方式">
          <el-select v-model="form.viewType" placeholder="请选择">
            <el-option label="在线查看" :value="1" />
            <el-option label="下载" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="行业">
          <el-select v-model="form.businessId" placeholder="请选择">
            <el-option
              v-for="item in area.reportBusiness"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img
              v-if="form.banner"
              :src="form.banner"
              class="avatar"
              style="width: 150px"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon" />
          </el-upload>
        </el-form-item>
        <el-form-item label="内容">
          <!-- <el-input v-model="form.content" type="textarea"></el-input> -->
          <quill-editor
            ref="myQuillEditor"
            v-model="form.content"
            :options="quillOption"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false
            form = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="add">{{ '确 定' }}</el-button>
      </span>
    </el-dialog>

    <el-dialog title="信息" :visible.sync="showinfo" width="40%" @close="">
      <el-form
        ref="form"
        :model="form_show"
        label-width="80px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="手机号">
          <span>{{ form_show.phone }}</span>
        </el-form-item>
        <el-form-item label="邮箱">
          {{ form_show.email }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
let that
import $ from 'jquery'
import rules from '../../utils/rules'
import show from '../../components/countDowns/show.vue'
import fuzzySelection from '@/components/fuzzySelection.vue'
export default {
  components: {
    Show: show,
    fuzzySelection
  },
  filters: {
    getarea: function(value) {
      // console.log(that.area.reportArea,value);
      if (that.area.reportArea) {
        var reportArea = that.area.reportArea[value]
        return reportArea
      }
    },
    getbu(value) {
      if (that.area.reportBusiness) {
        var reportBusiness = that.area.reportBusiness[value]
      }
      return reportBusiness
    },
    getviewType(val) {
      if (val == 1) {
        return '在线查看'
      } else {
        return '下载'
      }
    },
    getstau(val) {
      if (val == 1) {
        return '已发布'
      } else {
        return '未发布'
      }
    }
  },
  data() {
    return {
      dioA: false,
      showinfo: false,
      date: [],
      title: '',
      year: '',
      publishStatus: '',
      reportArea: '',
      reportBusiness: '',
      order: '',
      confing: '',
      btn: true,
      action: {
        action: 'report'
      },
      dioaddkuai: false,
      currentPage: 1,
      pagesize: 10,
      list: [],
      pai: [],
      paiform: {},
      value: '',
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      proof: false,
      form: {
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: '',
        desc: '',
        areaCode: '',
        banner: '',
        viewType: '',
        businessId: ''
      },
      form_2: {
        page: 1,
        pageSize: 10,
        keyword: '',
        waId: '',
        status: '',
        activityId: ''
        // order: ''
      },
      form_3: {
        phone: this.$cookies.get('phone')
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      form_show: {},
      area: []
    }
  },
  beforeCreate() {
    that = this
  },
  updated() {
    //   console.log(this.form);
  },
  created() {
    // this.getconfig()

    this.getlist()
    sessionStorage.setItem('action', 'report')
  },
  mounted() {
    console.log(rules.req('撒打算'))
  },
  methods: {
    reset() {
      this.form_2 = {
        page: 1,
        pageSize: 10,
        keyword: '',
        waId: '',
        status: '',
        activityId: ''
      }
      this.getlist()
    },
    fUser(row) {
      if (this.$toData(row.user)) {
        if (row.user.certStatus == 2) {
          return '已认证'
        }
        if (row.user.completeInfo == 1) {
          return '已完善'
        }
        return '已注册'
      }
      return ''
    },
    fUserColor(row) {
      if (this.$toData(row.user)) {
        if (row.user.certStatus == 2) {
          return 'success'
        }
        if (row.user.completeInfo == 1) {
          return ''
        }
        return 'warning'
      }
      return ''
    },
    async shelves(row) {
      let { data: res } = await this.$http.post(
        '/admin/UserSpeaker/editStatus',
        {
          id: row.id,
          status: +row.status ? 0 : 1
        }
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlist()
      }
    },
    subimt() {
      this.currentPage = 1
      this.form_2.page = 1
      this.getlist()
    },
    check() {
      console.log(1)
    },
    information(row) {
      this.showinfo = true
      // this.form_show = row
      this.$http
        .get('/admin/UserSpeaker/getById', { params: { id: row.id } })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.form_show = res.data
          }
        })
    },
    datum(row) {
      if (!row.document) {
        this.$message.error('当前暂无文件！')
        return
      }
      location.href = this.baseurl + this.yu + row.document
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'report_order_num'
        } else if (c.prop == 'views') {
          s = 'report_views'
        } else {
          s = 'report_' + c.prop
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    showinput(row) {
      row.pai = true
      this.list = [...this.list]
      setTimeout(() => {
        $('.id input').focus()
      }, 20)
    },

    handleInputBlur(row) {
      this.$http
        .post('/admin/UserSpeaker/editRemark', row)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          }
        })
    },
    addkuai() {
      this.addkuai = false
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
        }
      })
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.currentPage = e
      // this.getlist()
      var form = this.form_2
      form = {
        ...form,
        page: this.currentPage
      }
      this.getlist(form)
    },
    getlist(form) {
      var arr = form || this.form_2
      this.$http
        .get('/admin/UserSpeaker/getList', {
          params: {
            ...arr
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res)
            this.list = res.data.list
            this.total = res.data.totalCount
            // console.log(this.total);

            this.list.forEach(item => {
              if (
                item.waId &&
                item.waId.length > 0 &&
                res.data.waId != 'false'
              ) {
                item.waId = item.waId.split(',')
              }
            })

            // this.getarea()
            this.list.forEach(item => {
              this.pai.push({ id: item.id, pai: false })
            })
            // this.getea()
          }
        })
    },
    checkf(val) {
      this.form_3 = val
      // console.log(this.form_3)
    },
    async beforedown() {
      // const phone = this.$cookies.get('phone')
      const phone = '15080329619'
      if (!phone) {
        this.$message.error('没有绑定手机号')
        return
      }
      if (!this.proof) {
        this.dioA = true
      } else {
        this.download()
      }
    },
    async download() {
      if (this.btn) {
        this.btn = false
        // const { data: res } = await this.$http.get(
        //   '/admin/Common/checkSmsCode',
        //   {
        //     params: {
        //       ...this.form_3
        //     }
        //   }
        // )
        // this.btn = true
        // // console.log(this.btn)
        // this.$forceUpdate()
        if (false) {
          // this.$message.error(res.message)
          // return
        } else {
          const { data: res } = await this.$http.get(
            '/admin/UserSpeaker/exportExcel',
            {
              params: {
                ...this.form_2,
                ...this.form_3
              }
            }
          )
          this.proof = true
          this.btn = true
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            location.href = res.data.url
            this.dioA = false
          }
        }
      }
    },
    add() {},
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },

    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.banner = res.data.url
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },

    addedit() {
      if (this.btn) {
        // this.form.picture = this.form.picture.slice(this.baseurl.length)

        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.dialogVisiblexui = false
              this.$message.success(res.message)
              this.getlist()
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      sessionStorage.setItem('flag', false)
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/UserSpeaker/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/Report/publish', { id: id, publishStatus: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          }
        })
    },
    chagne(row) {
      this.$http
        .post('/admin/UserSpeaker/editService', {
          id: row.id,
          waId: row.waId.join(',')
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.getlist()
          } else {
            this.getlist()
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.out .input {
  width: 18%;
}
.el-card {
  position: relative;
}
.out .right {
  float: right;
}
</style>
